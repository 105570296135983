<template>
  
  <router-view/>
</template>

<style>
#app {
  height: 100vh;
  /* font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
    '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
  line-height: 1.5;
  color: #515a6e;
  font-size: 14px;
  background-color: #f7f7f7; */
  margin:0px;
  padding: 0;
  
}
body{
  margin: 0px;
}
html{
   overflow-y: hidden;
}

</style>
