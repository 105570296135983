import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus, { ElIcon } from 'element-plus'
import router from './router'
// import store from './store'
import store, { key } from '@/store'
import { getAuthorizationToken } from '@/utils/LocalStorage'

import 'element-plus/dist/index.css';
import { LoadIcons } from '@/utils/loadIcons/index'


router.beforeEach((to, from, next) => {
    const token = getAuthorizationToken();
    if (!token && to.name !== 'login') {
        next({ name: 'login' ,query:{t:to.fullPath}});
    } else if (token && to.name === 'login') {
        next({ name: 'main' })
    } else {
        if (store.state.loaded == 0) {
            store.commit('addRouters', router);
            store.commit('addLoaded');
            next({ ...to, replace: true })

        } else
            next();
    }
    if (to.meta.title) {
        setTitle(to.meta.title as string);
    }
});

function setTitle(title:string){
    document.title = title;
    store.commit('setDocTitle',title);
}

const app = createApp(App);
LoadIcons(app);

app.use(store, key).use(router).use(ElementPlus).mount('#app')

